@font-face {
  font-family: "Avenir Black";
  src: url("../fonts/Avenir Black.eot");
  src: url("../fonts/Avenir Black.eot?#iefix") format("embedded-opentype"),
    // url("../fonts/Avenir Black.woff2") format("woff2"),
    url("../fonts/Avenir Black.woff") format("woff"),
    url("../fonts/Avenir Black.ttf") format("truetype"),
    url("../fonts/Avenir Black.svg#Avenir Black") format("svg");
}

@font-face {
  font-family: "Avenir Heavy";
  src: url("../fonts/Avenir Heavy.eot");
  src: url("../fonts/Avenir Heavy.eot?#iefix") format("embedded-opentype"),
    // url("../fonts/Avenir Heavy.woff2") format("woff2"),
    url("../fonts/Avenir Heavy.woff") format("woff"),
    url("../fonts/Avenir Heavy.ttf") format("truetype"),
    url("../fonts/Avenir Heavy.svg#Avenir Heavy") format("svg");
}

@font-face {
  font-family: "Avenir Light";
  src: url("../fonts/Avenir Light.eot");
  src: url("../fonts/Avenir Light.eot?#iefix") format("embedded-opentype"),
    // url("../fonts/Avenir Light.woff2") format("woff2"),
    url("../fonts/Avenir Light.woff") format("woff"),
    url("../fonts/Avenir Light.ttf") format("truetype"),
    url("../fonts/Avenir Light.svg#Avenir Light") format("svg");
}

@font-face {
  font-family: "Avenir Regular";
  src: url("../fonts/Avenir Regular.eot");
  src: url("../fonts/Avenir Regular.eot?#iefix") format("embedded-opentype"),
    // url("../fonts/Avenir Regular.woff2") format("woff2"),
    url("../fonts/Avenir Regular.woff") format("woff"),
    url("../fonts/Avenir Regular.ttf") format("truetype"),
    url("../fonts/Avenir Regular.svg#Avenir Regular") format("svg");
}

// Using Variables
$primary: #339ade;
$secondary: #339ade;
$bodyColor: #fff;
$bodyColorBottomOverlay: #f8f4f4;
$headerHeight: 82px;
$fontFamilyAvenirBlack: "Avenir Black" !important;
$fontFamilyAvenirHeavy: "Avenir Heavy" !important;
$fontFamilyAvenirRegular: "Avenir Regular" !important;
$fontFamilyAvenirLight: "Avenir Light" !important;

// $primary: #727cf5;
// $secondary: #6c757d;
// $info: #84989e;
// $success: #0acf97;
// $danger: #fa5c7c;
// $grey: #adb5bd;
// $lightGrey: #f1f3fa;
// $boxShadow: 0 0 35px 0 #c5c5c526;
// $borderRadius: 5px;
