@charset "UTF-8";
@import "./var.scss";
@import "react-datepicker/dist/react-datepicker.css";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: $fontFamilyAvenirRegular;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: $fontFamilyAvenirRegular;
  font-size: 14px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  color: #303030;
  text-align: left;
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background-color: $primary;
  outline: 1px solid $primary;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.app-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
  overflow: hidden;
}

.maintenance-mode {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  max-width: 400px;
  margin: 40px 30px;
}

.app-header {
  background: #fff;
  height: $headerHeight;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 10;
  transition: all 0.2s;

  .app-header__content {
    display: flex;
    align-items: center;
    align-content: center;
    flex: 1;
    padding: 0 1.3rem;
    height: 60px;

    .app-header-left {
      display: flex;
      align-items: center;
      margin-right: 1.3rem;
    }

    .app-header-right {
      align-items: center;
      display: flex;
      margin-left: auto;
    }
  }

  .app-header__logo {
    padding: 0 1.3rem;
    height: 45px;
    width: auto;
    display: flex;
    align-items: center;
    transition: width 0.2s;
    border-right: 2px solid #707070;

    .logo-src {
      height: 40px;
      width: 148px;
      background: url("../images/logo.svg");
    }
  }
}

.fixed-header {
  .app-header {
    position: fixed;
    width: 100%;
    top: 0;

    .app-header__logo {
      visibility: visible;
    }
  }

  .app-main {
    padding-top: calc(#{$headerHeight} + 0px);
  }
}

.hide-header {
  .app-header {
    display: none;
  }

  .app-main {
    padding-top: 0;
  }
}

.app-header.header-shadow {
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03),
    0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03),
    0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05),
    0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
}

.app-main {
  flex: 1;
  display: flex;
  z-index: 8;
  position: relative;
  display: block;

  .app-main__outer {
    flex: 1;
    flex-direction: column;
    display: flex;
    z-index: 12;
  }

  .app-main__inner {
    padding: 25px 20px 0;
    flex: 1;
  }
}

.wrapper {
  background: $bodyColor;
}

.bg-wrapper {
  padding: 10px;
  // margin: -30px -30px;
  position: relative;
  background: $bodyColorBottomOverlay;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 0;
  margin-top: 15px;
}

.card {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border-width: 0;
  transition: all 0.2s;
  border-radius: 7px;
}

.card-body {
  padding: 0.5rem 1rem;
}

.stats-card-body {
  padding: 0.5rem;
}

// ReUsabel

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.text-xs {
  font-size: 0.9rem;
}

.text-sm {
  font-size: 1.3rem;
}

.text-lg {
  font-size: 1.6rem;
}

.text-xlg {
  font-size: 2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
  white-space: nowrap;
}

.pr-0 {
  padding-right: 0;
}

.bg-transparent {
  background: transparent;
}

.font-heavy {
  font-size: 14px;
  font-family: $fontFamilyAvenirHeavy;
}

.font-heavy-16 {
  font-size: 16px;
  font-family: $fontFamilyAvenirHeavy;
}

.font-heavy-18 {
  font-size: 18px;
  font-family: $fontFamilyAvenirHeavy;
}

.font-heavy-19 {
  font-size: 19px;
  font-family: $fontFamilyAvenirHeavy;
}

.font-heavy-20 {
  font-size: 20px;
  font-family: $fontFamilyAvenirHeavy;
}

.font-heavy-30 {
  font-size: 30px;
  font-family: $fontFamilyAvenirHeavy;
}

.font-regular {
  font-size: 14px;
  font-family: $fontFamilyAvenirRegular;
}

.font-regular-11 {
  font-size: 11px;
  font-family: $fontFamilyAvenirRegular;
}

.font-regular-12 {
  font-size: 12px;
  font-family: $fontFamilyAvenirRegular;
}

.font-regular-12-light {
  letter-spacing: 1px;
  color: #8a8a8a;
  font-size: 12px;
  font-family: $fontFamilyAvenirRegular;
}

.font-regular-18 {
  font-size: 18px;
  font-family: $fontFamilyAvenirRegular;
}

.font-regular-20 {
  font-size: 20px;
  font-family: $fontFamilyAvenirRegular;
}

.font-regular-22 {
  font-size: 22px;
  font-family: $fontFamilyAvenirRegular;
}

.font-regular-24 {
  font-size: 18px;
  font-family: $fontFamilyAvenirRegular;
}

.confirmation-btn {
  background: #22c8fc;
  border: 1px solid #22c8fc;
}

.font-regular-26 {
  font-size: 26px;
  font-family: $fontFamilyAvenirRegular;
}

.font-regular-28 {
  font-size: 28px;
  font-family: $fontFamilyAvenirRegular;
}

.font-regular-30 {
  font-size: 30px;
  font-family: $fontFamilyAvenirRegular;
}

.font-light {
  font-size: 14px;
  font-family: $fontFamilyAvenirLight;
}

.font-light-12 {
  letter-spacing: 0.6px;
  font-size: 12px;
  font-family: $fontFamilyAvenirLight;
}

.font-black {
  font-size: 14px;
  font-family: $fontFamilyAvenirBlack;
}

.font-black-54 {
  font-size: 54px;
  font-family: $fontFamilyAvenirBlack;
}

.create-event-btn {
  background: #22c8fc;
  border: 1px solid #22c8fc;
  white-space: nowrap;
  color: #fff;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #06abdf;
    border: 1px solid #06abdf;
  }
}

.add-session-btn {
  background: #22c8fc;
  border: 1px solid #22c8fc;
  white-space: nowrap;
  color: #fff;
  padding: 8px 8px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #06abdf;
    border: 1px solid #06abdf;
  }
}

.sessionGridTitle {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

.input-search {
  width: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;

  .search-apply-btn {
    border: 0;
    margin-left: 10px;
    position: absolute;
    right: 0;
    background: #dfdfdf; // Input border color #ced4da
    width: 40px;
    height: 100%;
    border-radius: 0 5px 5px 0;
    border: 1px solid #ced4da;
    border-style: solid solid solid none;

    // The below media query is for safari/iOS browser
    // @media not all and (min-resolution: 0.001dpcm) {
    //   @supports (-webkit-appearance: none) {
    //     right: 2px;
    //   }
    // }
  }
  .input-search-icon {
    position: absolute;
    z-index: 2;
    display: block;
    width: 3.375rem;
    height: 3.375rem;
    line-height: 3.75rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    font-size: 22px;
  }

  .input-search-control {
    // padding-left: 3.375rem;
    padding-right: 1.375rem;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .fa-search::before {
    content: url("../icons/icon-search.svg");
  }
}

.search-icon-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.btn-search-icon {
  padding-left: 1px;
  // padding-top: 3px;
  // The below media query is for safari/iOS browser
  // @media not all and (min-resolution: 0.001dpcm) {
  //   @supports (-webkit-appearance: none) {
  //     right: 3px;
  //   }
  // }
}

.carousel-arrow {
  .arrow {
    position: absolute;
    top: 0;
    height: 100%;
    background: #fff;
    // padding: 1px;
    border: 0;
  }

  .right {
    right: -1px;
    padding-right: 3px;
    img {
      &:hover {
        border-radius: 50%;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12),
          0 4px 8px rgba(0, 0, 0, 0.06);
      }
    }
    // The below media query is for safari/iOS browser
  }

  .left {
    left: -1px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    padding-left: 3px;

    img {
      &:hover {
        border-radius: 50%;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12),
          0 4px 8px rgba(0, 0, 0, 0.06);
      }
    }
  }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      right: 2px;
    }
  }
}

.carousel-home {
  .carousel-btn-default {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    width: 100% !important;
    color: #404040;
    font-weight: bold;
    border: 1px solid #cfcfcf;
    padding: 7px 13px;
    background-color: #fff;
    border-radius: 6px;
    width: auto;
    white-space: nowrap;

    &:hover {
      background: #22c8fc;
      border: 1px solid #22c8fc;
      color: #fff;
    }
  }

  .carousel-btn-info {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    width: 100% !important;
    color: #fff;
    font-weight: bold;
    border: 1px solid #22c8fc;
    padding: 7px 13px;
    background-color: #22c8fc;
    border-radius: 6px;
    width: auto;
    white-space: nowrap;
  }

  .react-multi-carousel-track {
    .react-multi-carousel-item {
      padding: 0 5px;
    }
  }
}

.carousel-terminal-wrap {
  .desc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .title {
    width: fit-content;
  }
  .carousel-card-wrapper {
    min-height: 98px;
    .card {
      height: 98px;
    }
    .carousel-content-wrapper {
      .with-icon {
        margin-right: 0.5rem;
      }

      .carousel-content {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.ellipsis-class {
  margin-right: 10px;
}

.carousel-content {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.carousel-card-wrapper-location {
  min-height: auto;
  .card {
    height: auto;
  }
  .carousel-content-wrapper {
    .with-icon {
      margin-right: 0.5rem;
    }

    .carousel-content {
      margin-right: 1rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.location-name {
  margin-right: 15px;
}

// margin-right is not working if this is placed inside carousel-content-wrapper
// so wrote outside
.with-icon {
  margin-right: 0.5rem;
  margin-bottom: 0.2rem;
}

.nav-tabs {
  border-bottom: none;
  justify-content: space-evenly;
  text-align: center;
}

.nav-tabs .nav-link {
  color: #303030;
  font-weight: 500;
  width: 105px;
  // white-space: ;
  // font-family: $fontFamilyAvenirRegular;
  // font-size: 12px;
  align-items: center;
  display: flex;
  font-weight: normal;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-top: #ffffff;
  border-right: #ffffff;
  border-left: #ffffff;
  border-bottom: 3px solid #2285c7;
  // font-family: $fontFamilyAvenirRegular;
  // font-size: 12px;
  font-weight: normal;
}

.tab-content {
  background: #f8f4f4;
  padding: 20px;
  margin-left: -20px;
  margin-right: -20px;
}

.img-grid {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  padding: 10px 0;
  // height: 300px;

  .img-item {
    margin: 4px;
    flex: 0 1 calc(25% - 8px);

    img {
      max-width: 100%;
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }
}

.pdf-grid {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  border-bottom: 3px solid #ccc;
  border-top: 3px solid #ccc;
  border-left: 0;
  border-right: 0;
  border-style: dashed;
  padding: 10px 0;

  .pdf-item {
    margin: 4px;
    flex: 0 1 calc(25% - 8px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #404040;

    img {
      align-self: center;
      height: auto;
      max-width: 100%;
      width: 50px;
    }
  }
}

.video-grid {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  padding: 10px 0;

  .video-item {
    margin: 4px;
    flex: 0 1 calc(33% - 8px);
    position: relative;
    display: inline-block;

    .video-img {
      height: auto;
      max-width: 100%;
      width: 300px;
    }

    .play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
    }
  }
}

// // .padding-20 {
// //   padding: 20px !important;
// // }

// // .container {
// //   padding: 0;
// // }

form {
  .form-group {
    .label-watermark {
      color: #00000054;
      font-size: 9px;
      margin-left: 13px;
    }
    .form-label {
      margin-bottom: 0.1rem;
      color: #3a3a3a;
    }
    select {
      cursor: pointer;
      appearance: menulist !important;
      -webkit-appearance: menulist !important;
    }
    .form-control {
      background: #f9f9f9;
      padding: 8px;
      color: #3a3a3a;
    }
    .react-select-form-control {
      background: #f9f9f9;
      padding: 0 !important;
      color: #3a3a3a;
    }
  }
}

.add-btn-small {
  white-space: nowrap;
  color: #3a3a3a;
  padding: 5px 18px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #cfcfcf !important;
  cursor: pointer;

  &:active {
    border: 1px solid #cfcfcf !important;
    background: #fff !important;
  }

  &:focus {
    box-shadow: none !important;
    border: 1px solid #cfcfcf !important;
    background: #fff !important;
  }

  @media (hover: none) {
    border: 1px solid #cfcfcf !important;
    background: #fff !important;
  }
}

.fa-plus {
  color: #bebebe;
  font-size: 25px;
  // margin-top: 50px;

  @media (max-width: 1281px) {
    display: none;
  }

  @media (max-width: 380px) {
    display: contents;
  }
}

#btn-text {
  @media (max-width: 380px) {
    display: none;
    border: 0 !important;
  }
}

.add-btn-location {
  margin-top: 15px;
  margin-left: auto;
  width: 80px;
  height: 50px;

  @media (max-width: 380px) {
    margin-top: 15px;
    width: 40px;
    height: 50px;
    border: 0 !important;

    &:active {
      border: 0 !important;
    }

    &:focus {
      // margin-top: 15px;
      box-shadow: none !important;
      border: 0 !important;
      background: #fff !important;
    }
  }
}

.add-notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e1ecf3;
  border-radius: 5px;
  padding: 4px 12px;
  color: #8a8a8a;
  margin: 10px 0;
  box-shadow: "0px 3px 6px #00000029";

  .add-notification-desc {
    margin: 0 5px;
  }

  .add-notification-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      cursor: pointer;
      margin-top: 3px;
    }
  }
}

.user-detail-card {
  .react-multi-carousel-list {
    .carousel-arrow {
      button {
        top: 40% !important;
        height: fit-content;
        background: transparent;
      }
    }
  }

  .react-multi-carousel-item {
    // width: auto !important;
    .card {
      border-width: thin;
      margin: 18px 0;
      color: #242424;
      min-height: 105px;
    }
  }
}

.user-detail-card-location {
  overflow: hidden;
  .react-multi-carousel-list {
    white-space: nowrap;
    .carousel-arrow {
      button {
        top: 40% !important;
        height: fit-content;
        background: transparent;
      }
    }
  }

  .react-multi-carousel-track {
    white-space: nowrap;
  }

  .react-multi-carousel-item {
    width: auto !important;
    .card {
      border-width: thin;
      margin: 18px 0;
      color: #242424;
      min-height: 40px;
    }
  }
}

.container-locations {
  padding-top: 2px;
  padding-bottom: 4px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
}

.testimonial-detail-card {
  .testimonia-doc-count {
    margin: 0 10px 0 1px;
  }

  .react-multi-carousel-list {
    .carousel-arrow {
      button {
        top: 40% !important;
        height: fit-content;
        background: transparent;
      }
    }
  }
  .react-multi-carousel-item {
    // width: auto !important;
    .card {
      border-width: thin;
      margin: 18px 0;
      color: #242424;
    }
  }
}

.add-event-footer-btns {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;

  .discard-btn-large {
    border-radius: 30px;
    background: #fff;
    border: 1px solid #cfcfcf;
    color: #404040;
    cursor: pointer;
    width: 330px;
    margin: 10px;
    font-weight: 600;

    &:hover {
      background: #ccc;
      border: 1px solid #ccc;
    }
  }

  .discard-btn-small {
    border-radius: 30px;
    background: #fff;
    border: 1px solid #cfcfcf;
    color: #404040;
    cursor: pointer;
    width: 200px;
    margin: 10px;

    &:hover {
      background: #ccc;
      border: 1px solid #ccc;
    }
  }

  .home-btn-large {
    border-radius: 30px;
    background: #fff;
    border: 1px solid #cfcfcf;
    color: #404040;
    cursor: pointer;
    width: 100px;
    margin: 10px;

    &:hover {
      background: #ccc;
      border: 1px solid #ccc;
    }
  }

  .save-btn-large {
    border-radius: 30px;
    background: #22c8fc;
    border: 1px solid #22c8fc;
    color: #fff;
    cursor: pointer;
    width: 330px;
    margin: 10px;

    &:hover {
      background: #06abdf;
      border: 1px solid #06abdf;
    }
  }
}

// Custom Styles For Radio & Checkbox
.container-check {
  display: flex;
  margin: 10px 0;

  .form-check {
    display: flex;
    align-items: center;
    margin-right: 20px;

    .form-check-input {
      width: 1.375rem;
      height: 1.375rem;
      margin-top: 0;
      margin-right: 10px;

      &:checked {
        background-color: #1a81c5;
        border-color: #1a81c5;
      }
    }
  }
}

.container-checkbox-lg {
  display: flex;
  align-items: center;
  // margin-top: 5px;

  .form-check {
    display: flex;
    align-items: center;
    margin-right: 20px;

    .form-check-input {
      min-width: 1.8rem;
      min-height: 1.8rem;
      margin-top: 0;
      margin-right: 10px;

      &:checked {
        background-color: #1a81c5;
        border-color: #1a81c5;
      }
    }
  }
}

.upload-picture {
  display: flex;
  align-items: center;
  position: relative;

  .picture-count {
    flex-grow: 0;
    flex-shrink: 1;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-right: 20px;

    div {
      color: #fff;
      font-weight: bold;
      font-size: 3rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .add-more {
    cursor: pointer;
    position: relative;
  }

  .picture-message {
    margin-left: 20px;
  }
}

.stats-card {
  min-height: 60px;
  min-width: 60px;
  margin-right: 8px;
  height: 100%;
  width: 100%;
  // cursor: pointer;

  // &:hover {
  //   transform: scale(1.05);
  //   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  // }
}

.icon-hover-effect {
  &:hover {
    opacity: 0.7;
    border-radius: 15px;
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
  }
}

// .govt-flag-input {
//   display: flex;
//   align-items: center;
// }

.event-card {
  box-shadow: #0000001f 0px 3px 6px;

  .event-card-title {
    font-size: 12px;
    .title-session {
      line-height: 1.8;
    }
  }

  .event-card-bts {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;

    span {
      padding: 0 5px;
    }

    .fa-camera::before {
      content: url("../icons/icon-camera.svg");
    }

    .fa-user::before {
      content: url("../icons/icon-user.svg");
    }

    .fa-edit::before {
      content: url("../icons/icon-edit.svg");
    }

    .fa-add::before {
      content: url("../icons/icon-add.svg");
    }
  }
}

.required:after {
  content: " *";
  color: red;
}

.is-invalid {
  border: 1px solid red;
}

.is-invalid-feedback {
  color: red;
  margin: 4px 0 0 0;
  &.file-size-error {
    position: absolute;
    top: -45px;
    font-size: 11px;
    width: 99%;
    background: #fff2f2;
    padding: 2px 3px;
    border: solid 1px;
  }
}

.radio-group-container {
  display: flex;
  text-align: center;
  align-items: center;
  margin: 10px 0;

  label {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 30px;

    input {
      width: 1.375rem;
      height: 1.375rem;
      margin-top: 0;
    }
  }
}

.form-check-input {
  min-width: 1.8rem;
  min-height: 1.8rem;
  margin-top: -3px;
  margin-right: 10px;
}

.is-img-loading {
  opacity: 0;
}

.is-img-loaded {
  animation: fadeIn 0.3s ease;
  opacity: 1;
}

.loader-box {
  width: 100%;
  height: inherit;
  position: relative;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: fadeIn 0.3s ease;
}

.card-remove {
  position: absolute;
  right: 0;
  padding: 6px 10px;
  font-size: 15px;
  top: 2px;
  cursor: pointer;
  .close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    i {
      color: #6f6f6f;
    }
  }
}

.btn-transparent {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  i {
    color: #6f6f6f;
  }
}

/* Confirm dialog */
.confirm-dialog-wrapper {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  .alert {
    top: 10%;
    margin: 10%;
  }
}

/* Status info */
.status_info {
  display: grid;
  grid-template-columns: repeat(3, 34%);
  .inner_card {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: none;
    }
  }
  @media (max-width: 640px) {
    .font-light-12 {
      font-size: 10px;
    }
    .font-heavy-30 {
      font-size: 24px;
    }
  }
  @media (max-width: 380px) {
    grid-template-columns: repeat(3, 34%);
  }
}

.input-file-type-container {
  // width: 100%;
  position: relative;
  .input-file-type-wrapper {
    .form-label {
      width: 100%;
      .input-with-icon {
        align-items: center;
        display: flex;

        .paper-clip-icon {
          right: 15px !important;
          font-size: 25px;
          transform: rotate(180deg) scaleX(-1);
        }

        .icon {
          position: absolute;
          right: 10px;
        }

        .iconDown {
          cursor: pointer;
          font-size: 30px;
          color: #bebebe;
          right: 5px;
          bottom: 4px;
          background: #f9f9f9;
          width: 25px;
        }

        .calenderIcon {
          cursor: pointer;
          font-size: 20px;
          color: #bebebe;
          right: 25px;
        }
      }
    }

    .input-calender-type {
      .is-invalid-feedback {
        opacity: 0;
      }
      .react-datepicker-wrapper {
        opacity: 0;
      }
      width: 99%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      // z-index: 10; // commented this to enable next month of start date
    }

    .input-file-type {
      width: 100%;
      height: 100%;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 10;
    }

    .input-file-type-testmonial {
      .input-file-type-opacity {
        opacity: 0;
      }
      width: 100%;
      height: 100%;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }
}

.upload-file-container {
  width: fit-content;
}

.date-picker-wrapper {
  .datepicker-label {
    ::after {
      color: red;
      content: "*";
    }
  }
  .RDP-container {
    text-align: center;
  }
  .custom-button-date-picker {
    width: 96%;
    height: 40px;
  }
  .error {
    color: red;
  }
}

.dropdown-container {
  .dropdown {
    padding: 0 !important;
    .input-error:focus {
      box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%) !important;
    }

    .dropdown-toggle {
      width: 100%;
      text-align: left;
    }
    .dropdown-toggle::after {
      display: none !important;
    }
    .toggle-menu {
      width: 100%;
    }
  }
}

.pagination-container {
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 10px;
  right: 10px;
  background-color: white;
  border-radius: 3px;
  padding: 0 10px;
  margin-bottom: 2px;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: "";
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.dropdown-select__control.dropdown-select__control--is-focused {
  font-size: 16px !important;
}
.modal-content {
  background-color: #f9f9f9;
}
.add-button-cus {
  background-color: #22c8fc !important;
  &:active {
    background: #22c8fc !important;
  }
  &:focus {
    background: #22c8fc !important;
  }
  @media (hover: none) {
    background: #22c8fc !important;
  }
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;

  color: black;
}
