@import "./var.scss";

.loader {
  margin: 0 auto;
  margin-top: 10rem;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 5px solid transparent;
  animation: spin 1s linear infinite;
  background: linear-gradient(white, white),
    conic-gradient(from 0.15turn, white, $primary);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
