.preloader {
  width: 100%;
  height: 100%;
  padding: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  animation: fadeIn 0.5s ease;

  img {
    width: 200px;
    height: 120px;
    object-fit: contain;
  }
}

.logo-symbol {
  width: 70px;
  height: 70px;
  animation: rotate 1s ease infinite;

  .fill-white {
    fill: #fff;
  }
}

@keyframes rotate {
  90% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
